import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _442485af = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _02270576 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _42156be0 = () => interopDefault(import('../pages/inquiry/index.vue' /* webpackChunkName: "pages/inquiry/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _49c57cf4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _cc080460 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _dc3c4e42 = () => interopDefault(import('../pages/reminder/index.vue' /* webpackChunkName: "pages/reminder/index" */))
const _74c7bc7c = () => interopDefault(import('../pages/admin/activity/index.vue' /* webpackChunkName: "pages/admin/activity/index" */))
const _f28cae46 = () => interopDefault(import('../pages/admin/changelog/index.vue' /* webpackChunkName: "pages/admin/changelog/index" */))
const _ce056496 = () => interopDefault(import('../pages/admin/chatlog/index.vue' /* webpackChunkName: "pages/admin/chatlog/index" */))
const _163c6154 = () => interopDefault(import('../pages/admin/chatwork/index.vue' /* webpackChunkName: "pages/admin/chatwork/index" */))
const _b8e44ff4 = () => interopDefault(import('../pages/admin/company/index.vue' /* webpackChunkName: "pages/admin/company/index" */))
const _3c083be9 = () => interopDefault(import('../pages/admin/companyregister/index.vue' /* webpackChunkName: "pages/admin/companyregister/index" */))
const _fdd8bc46 = () => interopDefault(import('../pages/admin/dashboard/index.vue' /* webpackChunkName: "pages/admin/dashboard/index" */))
const _04937b88 = () => interopDefault(import('../pages/admin/document/index.vue' /* webpackChunkName: "pages/admin/document/index" */))
const _3db92163 = () => interopDefault(import('../pages/admin/event/index.vue' /* webpackChunkName: "pages/admin/event/index" */))
const _fbf698ae = () => interopDefault(import('../pages/admin/file/index.vue' /* webpackChunkName: "pages/admin/file/index" */))
const _259317b6 = () => interopDefault(import('../pages/admin/fixedphrase/index.vue' /* webpackChunkName: "pages/admin/fixedphrase/index" */))
const _062d34f0 = () => interopDefault(import('../pages/admin/handout/index.vue' /* webpackChunkName: "pages/admin/handout/index" */))
const _f4cf025c = () => interopDefault(import('../pages/admin/innovation/index.vue' /* webpackChunkName: "pages/admin/innovation/index" */))
const _099890d0 = () => interopDefault(import('../pages/admin/inquiry/index.vue' /* webpackChunkName: "pages/admin/inquiry/index" */))
const _739a053d = () => interopDefault(import('../pages/admin/label/index.vue' /* webpackChunkName: "pages/admin/label/index" */))
const _4d3de641 = () => interopDefault(import('../pages/admin/mall/index.vue' /* webpackChunkName: "pages/admin/mall/index" */))
const _500ac840 = () => interopDefault(import('../pages/admin/news/index.vue' /* webpackChunkName: "pages/admin/news/index" */))
const _4ae975b4 = () => interopDefault(import('../pages/admin/newsletter/index.vue' /* webpackChunkName: "pages/admin/newsletter/index" */))
const _25351355 = () => interopDefault(import('../pages/admin/partnerform/index.vue' /* webpackChunkName: "pages/admin/partnerform/index" */))
const _607b4c29 = () => interopDefault(import('../pages/admin/pickup/index.vue' /* webpackChunkName: "pages/admin/pickup/index" */))
const _7f5dad2c = () => interopDefault(import('../pages/admin/promotion/index.vue' /* webpackChunkName: "pages/admin/promotion/index" */))
const _68e35338 = () => interopDefault(import('../pages/admin/user/index.vue' /* webpackChunkName: "pages/admin/user/index" */))
const _7816f4fc = () => interopDefault(import('../pages/chat/remark-body.vue' /* webpackChunkName: "pages/chat/remark-body" */))
const _072d5432 = () => interopDefault(import('../pages/disconnect/broker.vue' /* webpackChunkName: "pages/disconnect/broker" */))
const _750d7009 = () => interopDefault(import('../pages/inquiry/completed.vue' /* webpackChunkName: "pages/inquiry/completed" */))
const _5add6518 = () => interopDefault(import('../pages/mypage/babank/index.vue' /* webpackChunkName: "pages/mypage/babank/index" */))
const _cb558198 = () => interopDefault(import('../pages/mypage/bachat/index.vue' /* webpackChunkName: "pages/mypage/bachat/index" */))
const _32a5f758 = () => interopDefault(import('../pages/mypage/bidding/index.vue' /* webpackChunkName: "pages/mypage/bidding/index" */))
const _298055b5 = () => interopDefault(import('../pages/mypage/chat/index.vue' /* webpackChunkName: "pages/mypage/chat/index" */))
const _5251e7b3 = () => interopDefault(import('../pages/mypage/column/index.vue' /* webpackChunkName: "pages/mypage/column/index" */))
const _4158f8ad = () => interopDefault(import('../pages/mypage/dashboard/index.vue' /* webpackChunkName: "pages/mypage/dashboard/index" */))
const _a331840c = () => interopDefault(import('../pages/mypage/forum/index.vue' /* webpackChunkName: "pages/mypage/forum/index" */))
const _6f48fb44 = () => interopDefault(import('../pages/mypage/help/index.vue' /* webpackChunkName: "pages/mypage/help/index" */))
const _6403fbfc = () => interopDefault(import('../pages/mypage/innovation/index.vue' /* webpackChunkName: "pages/mypage/innovation/index" */))
const _24118d71 = () => interopDefault(import('../pages/mypage/mall/index.vue' /* webpackChunkName: "pages/mypage/mall/index" */))
const _5813331a = () => interopDefault(import('../pages/mypage/matching/index.vue' /* webpackChunkName: "pages/mypage/matching/index" */))
const _1c212149 = () => interopDefault(import('../pages/mypage/mybusiness/index.vue' /* webpackChunkName: "pages/mypage/mybusiness/index" */))
const _26de6f70 = () => interopDefault(import('../pages/mypage/news/index.vue' /* webpackChunkName: "pages/mypage/news/index" */))
const _6ca839a6 = () => interopDefault(import('../pages/mypage/sc/index.vue' /* webpackChunkName: "pages/mypage/sc/index" */))
const _0fd33f6c = () => interopDefault(import('../pages/mypage/seminar/index.vue' /* webpackChunkName: "pages/mypage/seminar/index" */))
const _ef917ef8 = () => interopDefault(import('../pages/mypage/subsidy/index.vue' /* webpackChunkName: "pages/mypage/subsidy/index" */))
const _96c43c7c = () => interopDefault(import('../pages/mypage/succession/index.vue' /* webpackChunkName: "pages/mypage/succession/index" */))
const _322130b0 = () => interopDefault(import('../pages/mypage/support/index.vue' /* webpackChunkName: "pages/mypage/support/index" */))
const _a1197114 = () => interopDefault(import('../pages/mypage/trend/index.vue' /* webpackChunkName: "pages/mypage/trend/index" */))
const _6b48f9da = () => interopDefault(import('../pages/page/bm_rule.vue' /* webpackChunkName: "pages/page/bm_rule" */))
const _3d7e72d7 = () => interopDefault(import('../pages/page/cookie_policy.vue' /* webpackChunkName: "pages/page/cookie_policy" */))
const _4091affc = () => interopDefault(import('../pages/page/rule.vue' /* webpackChunkName: "pages/page/rule" */))
const _ec1c0c0e = () => interopDefault(import('../pages/page/rule_portal.vue' /* webpackChunkName: "pages/page/rule_portal" */))
const _1b41d7be = () => interopDefault(import('../pages/page/rule_rc.vue' /* webpackChunkName: "pages/page/rule_rc" */))
const _63d29fac = () => interopDefault(import('../pages/page/suspending.vue' /* webpackChunkName: "pages/page/suspending" */))
const _ce8aaff0 = () => interopDefault(import('../pages/register/agreement.vue' /* webpackChunkName: "pages/register/agreement" */))
const _48bf9374 = () => interopDefault(import('../pages/register/ba/index.vue' /* webpackChunkName: "pages/register/ba/index" */))
const _4f499f5c = () => interopDefault(import('../pages/register/bizsol/index.vue' /* webpackChunkName: "pages/register/bizsol/index" */))
const _097b89c3 = () => interopDefault(import('../pages/register/ibmft/index.vue' /* webpackChunkName: "pages/register/ibmft/index" */))
const _c3f7e8f8 = () => interopDefault(import('../pages/register/payment.vue' /* webpackChunkName: "pages/register/payment" */))
const _8551033e = () => interopDefault(import('../pages/register/portal/index.vue' /* webpackChunkName: "pages/register/portal/index" */))
const _0c67dcdf = () => interopDefault(import('../pages/register/social.vue' /* webpackChunkName: "pages/register/social" */))
const _9d5c4032 = () => interopDefault(import('../pages/register/thankyou.vue' /* webpackChunkName: "pages/register/thankyou" */))
const _a3300150 = () => interopDefault(import('../pages/reminder/completed.vue' /* webpackChunkName: "pages/reminder/completed" */))
const _a1988584 = () => interopDefault(import('../pages/admin/bachat/noreply/index.vue' /* webpackChunkName: "pages/admin/bachat/noreply/index" */))
const _433cdd5e = () => interopDefault(import('../pages/admin/bachat/stats.vue' /* webpackChunkName: "pages/admin/bachat/stats" */))
const _7e08c134 = () => interopDefault(import('../pages/admin/chat/remarks.vue' /* webpackChunkName: "pages/admin/chat/remarks" */))
const _3c146b7c = () => interopDefault(import('../pages/admin/company/advertisement/index.vue' /* webpackChunkName: "pages/admin/company/advertisement/index" */))
const _ce9937e4 = () => interopDefault(import('../pages/admin/company/bulk-create/index.vue' /* webpackChunkName: "pages/admin/company/bulk-create/index" */))
const _03c7889a = () => interopDefault(import('../pages/admin/company/charge/index.vue' /* webpackChunkName: "pages/admin/company/charge/index" */))
const _143d1910 = () => interopDefault(import('../pages/admin/company/create.vue' /* webpackChunkName: "pages/admin/company/create" */))
const _0c304248 = () => interopDefault(import('../pages/admin/company/creditcard/index.vue' /* webpackChunkName: "pages/admin/company/creditcard/index" */))
const _4c5c379b = () => interopDefault(import('../pages/admin/company/favorite/index.vue' /* webpackChunkName: "pages/admin/company/favorite/index" */))
const _cae979b8 = () => interopDefault(import('../pages/admin/company/innovation/index.vue' /* webpackChunkName: "pages/admin/company/innovation/index" */))
const _483b5688 = () => interopDefault(import('../pages/admin/company/matching/index.vue' /* webpackChunkName: "pages/admin/company/matching/index" */))
const _67d4a214 = () => interopDefault(import('../pages/admin/company/needs/index.vue' /* webpackChunkName: "pages/admin/company/needs/index" */))
const _6b3dc37f = () => interopDefault(import('../pages/admin/company/partner/index.vue' /* webpackChunkName: "pages/admin/company/partner/index" */))
const _69e785ec = () => interopDefault(import('../pages/admin/company/user/index.vue' /* webpackChunkName: "pages/admin/company/user/index" */))
const _80a01d02 = () => interopDefault(import('../pages/admin/crowdfunding/campfire/index.vue' /* webpackChunkName: "pages/admin/crowdfunding/campfire/index" */))
const _94df7f94 = () => interopDefault(import('../pages/admin/document/create.vue' /* webpackChunkName: "pages/admin/document/create" */))
const _47140326 = () => interopDefault(import('../pages/admin/file/request.vue' /* webpackChunkName: "pages/admin/file/request" */))
const _96f7ac70 = () => interopDefault(import('../pages/admin/fixedphrase/create.vue' /* webpackChunkName: "pages/admin/fixedphrase/create" */))
const _0e6aee90 = () => interopDefault(import('../pages/admin/fixedphrase/form.vue' /* webpackChunkName: "pages/admin/fixedphrase/form" */))
const _7010d394 = () => interopDefault(import('../pages/admin/handout/create.vue' /* webpackChunkName: "pages/admin/handout/create" */))
const _275aed21 = () => interopDefault(import('../pages/admin/label/create.vue' /* webpackChunkName: "pages/admin/label/create" */))
const _fa179862 = () => interopDefault(import('../pages/admin/ncba/bm/index.vue' /* webpackChunkName: "pages/admin/ncba/bm/index" */))
const _4dfaeb04 = () => interopDefault(import('../pages/admin/news/create.vue' /* webpackChunkName: "pages/admin/news/create" */))
const _c2dcab50 = () => interopDefault(import('../pages/admin/newsletter/create.vue' /* webpackChunkName: "pages/admin/newsletter/create" */))
const _e0b50664 = () => interopDefault(import('../pages/admin/performance/branch/index.vue' /* webpackChunkName: "pages/admin/performance/branch/index" */))
const _63db4a77 = () => interopDefault(import('../pages/admin/performance/monthly/index.vue' /* webpackChunkName: "pages/admin/performance/monthly/index" */))
const _d7e779dc = () => interopDefault(import('../pages/admin/promotion/create.vue' /* webpackChunkName: "pages/admin/promotion/create" */))
const _66daac6f = () => interopDefault(import('../pages/admin/setting/global/index.vue' /* webpackChunkName: "pages/admin/setting/global/index" */))
const _b707f998 = () => interopDefault(import('../pages/admin/setting/invoice.vue' /* webpackChunkName: "pages/admin/setting/invoice" */))
const _7953871b = () => interopDefault(import('../pages/admin/setting/permission/index.vue' /* webpackChunkName: "pages/admin/setting/permission/index" */))
const _4cf031f8 = () => interopDefault(import('../pages/admin/user/admin/index.vue' /* webpackChunkName: "pages/admin/user/admin/index" */))
const _fa399a30 = () => interopDefault(import('../pages/admin/user/password/index.vue' /* webpackChunkName: "pages/admin/user/password/index" */))
const _12d0e431 = () => interopDefault(import('../pages/admin/xba/banner/index.vue' /* webpackChunkName: "pages/admin/xba/banner/index" */))
const _a94635a8 = () => interopDefault(import('../pages/admin/xba/bidding/index.vue' /* webpackChunkName: "pages/admin/xba/bidding/index" */))
const _eb92e84a = () => interopDefault(import('../pages/admin/xba/column/index.vue' /* webpackChunkName: "pages/admin/xba/column/index" */))
const _86737dbc = () => interopDefault(import('../pages/admin/xba/seminar/index.vue' /* webpackChunkName: "pages/admin/xba/seminar/index" */))
const _4ce7215c = () => interopDefault(import('../pages/admin/xba/subsidy/index.vue' /* webpackChunkName: "pages/admin/xba/subsidy/index" */))
const _225814f9 = () => interopDefault(import('../pages/mypage/chanto-attendance/registration.vue' /* webpackChunkName: "pages/mypage/chanto-attendance/registration" */))
const _93559376 = () => interopDefault(import('../pages/mypage/chanto-attendance/top.vue' /* webpackChunkName: "pages/mypage/chanto-attendance/top" */))
const _d6926494 = () => interopDefault(import('../pages/mypage/company/edit.vue' /* webpackChunkName: "pages/mypage/company/edit" */))
const _0f19904f = () => interopDefault(import('../pages/mypage/crowdfunding/campfire/index.vue' /* webpackChunkName: "pages/mypage/crowdfunding/campfire/index" */))
const _18e57123 = () => interopDefault(import('../pages/mypage/dashboard/employee.vue' /* webpackChunkName: "pages/mypage/dashboard/employee" */))
const _7287ea0e = () => interopDefault(import('../pages/mypage/dashboard/owner.vue' /* webpackChunkName: "pages/mypage/dashboard/owner" */))
const _14f0cf84 = () => interopDefault(import('../pages/mypage/forum/CategoryFilter.vue' /* webpackChunkName: "pages/mypage/forum/CategoryFilter" */))
const _1b16efcc = () => interopDefault(import('../pages/mypage/forum/form.vue' /* webpackChunkName: "pages/mypage/forum/form" */))
const _4f1016a6 = () => interopDefault(import('../pages/mypage/forum/list.vue' /* webpackChunkName: "pages/mypage/forum/list" */))
const _5393c53a = () => interopDefault(import('../pages/mypage/forum/policy.vue' /* webpackChunkName: "pages/mypage/forum/policy" */))
const _62128932 = () => interopDefault(import('../pages/mypage/forum/TopicCard.vue' /* webpackChunkName: "pages/mypage/forum/TopicCard" */))
const _d99936ba = () => interopDefault(import('../pages/mypage/mall/policy.vue' /* webpackChunkName: "pages/mypage/mall/policy" */))
const _5bc89cf8 = () => interopDefault(import('../pages/mypage/matching/campaign.vue' /* webpackChunkName: "pages/mypage/matching/campaign" */))
const _0858fb81 = () => interopDefault(import('../pages/mypage/matching/favca.vue' /* webpackChunkName: "pages/mypage/matching/favca" */))
const _b8225c48 = () => interopDefault(import('../pages/mypage/matching/history.vue' /* webpackChunkName: "pages/mypage/matching/history" */))
const _69fdf5be = () => interopDefault(import('../pages/mypage/matching/received_history/index.vue' /* webpackChunkName: "pages/mypage/matching/received_history/index" */))
const _2415b7da = () => interopDefault(import('../pages/mypage/matching/search/index.vue' /* webpackChunkName: "pages/mypage/matching/search/index" */))
const _6585963a = () => interopDefault(import('../pages/mypage/mybusiness/edit.vue' /* webpackChunkName: "pages/mypage/mybusiness/edit" */))
const _09c991ac = () => interopDefault(import('../pages/mypage/sc/admin.vue' /* webpackChunkName: "pages/mypage/sc/admin" */))
const _428bd487 = () => interopDefault(import('../pages/mypage/setting/account/index.vue' /* webpackChunkName: "pages/mypage/setting/account/index" */))
const _276fda24 = () => interopDefault(import('../pages/mypage/setting/chat_remark_template/index.vue' /* webpackChunkName: "pages/mypage/setting/chat_remark_template/index" */))
const _baab1574 = () => interopDefault(import('../pages/mypage/setting/information/index.vue' /* webpackChunkName: "pages/mypage/setting/information/index" */))
const _505f933e = () => interopDefault(import('../pages/mypage/setting/innovation/index.vue' /* webpackChunkName: "pages/mypage/setting/innovation/index" */))
const _2f8964f2 = () => interopDefault(import('../pages/mypage/setting/invoice/index.vue' /* webpackChunkName: "pages/mypage/setting/invoice/index" */))
const _dfff8658 = () => interopDefault(import('../pages/mypage/setting/password.vue' /* webpackChunkName: "pages/mypage/setting/password" */))
const _ba8bfb00 = () => interopDefault(import('../pages/mypage/setting/profile.vue' /* webpackChunkName: "pages/mypage/setting/profile" */))
const _54bdbeaa = () => interopDefault(import('../pages/mypage/setting/unsubscribe/index.vue' /* webpackChunkName: "pages/mypage/setting/unsubscribe/index" */))
const _68837975 = () => interopDefault(import('../pages/mypage/setting/website/index.vue' /* webpackChunkName: "pages/mypage/setting/website/index" */))
const _c1e75abc = () => interopDefault(import('../pages/mypage/subsidy/applysupport/index.vue' /* webpackChunkName: "pages/mypage/subsidy/applysupport/index" */))
const _2a6fa966 = () => interopDefault(import('../pages/mypage/subsidy/search.vue' /* webpackChunkName: "pages/mypage/subsidy/search" */))
const _385d93cc = () => interopDefault(import('../pages/mypage/succession/compleated.vue' /* webpackChunkName: "pages/mypage/succession/compleated" */))
const _75916f96 = () => interopDefault(import('../pages/mypage/support/document.vue' /* webpackChunkName: "pages/mypage/support/document" */))
const _5b7a6b67 = () => interopDefault(import('../pages/register/ba/components/index.vue' /* webpackChunkName: "pages/register/ba/components/index" */))
const _2604d11b = () => interopDefault(import('../pages/register/bizsol/as_employee.vue' /* webpackChunkName: "pages/register/bizsol/as_employee" */))
const _004d4d16 = () => interopDefault(import('../pages/register/bizsol/as_owner.vue' /* webpackChunkName: "pages/register/bizsol/as_owner" */))
const _948e4146 = () => interopDefault(import('../pages/register/bizsol/progress.vue' /* webpackChunkName: "pages/register/bizsol/progress" */))
const _16f17598 = () => interopDefault(import('../pages/register/employee/thankyou.vue' /* webpackChunkName: "pages/register/employee/thankyou" */))
const _635e91cc = () => interopDefault(import('../pages/register/ibmft/as_employee.vue' /* webpackChunkName: "pages/register/ibmft/as_employee" */))
const _38549bf6 = () => interopDefault(import('../pages/register/ibmft/as_owner.vue' /* webpackChunkName: "pages/register/ibmft/as_owner" */))
const _cd7d7768 = () => interopDefault(import('../pages/register/ibmft/progress.vue' /* webpackChunkName: "pages/register/ibmft/progress" */))
const _34888184 = () => interopDefault(import('../pages/register/portal/enable.vue' /* webpackChunkName: "pages/register/portal/enable" */))
const _5f5fef90 = () => interopDefault(import('../pages/register/portal/enabled.vue' /* webpackChunkName: "pages/register/portal/enabled" */))
const _5bb88d3d = () => interopDefault(import('../pages/register/portal/index_as_employee.vue' /* webpackChunkName: "pages/register/portal/index_as_employee" */))
const _2d05d434 = () => interopDefault(import('../pages/register/portal/index_as_owner.vue' /* webpackChunkName: "pages/register/portal/index_as_owner" */))
const _468849b6 = () => interopDefault(import('../pages/register/portal/thankyou.vue' /* webpackChunkName: "pages/register/portal/thankyou" */))
const _7b3905c4 = () => interopDefault(import('../pages/admin/bachat/interbank/contact/index.vue' /* webpackChunkName: "pages/admin/bachat/interbank/contact/index" */))
const _228ef874 = () => interopDefault(import('../pages/admin/bachat/interbank/partner/index.vue' /* webpackChunkName: "pages/admin/bachat/interbank/partner/index" */))
const _8e017586 = () => interopDefault(import('../pages/admin/bachat/support/bank/index.vue' /* webpackChunkName: "pages/admin/bachat/support/bank/index" */))
const _16a017c1 = () => interopDefault(import('../pages/admin/bachat/support/bb/index.vue' /* webpackChunkName: "pages/admin/bachat/support/bb/index" */))
const _18477350 = () => interopDefault(import('../pages/admin/boy/intrauser/import.vue' /* webpackChunkName: "pages/admin/boy/intrauser/import" */))
const _ae7f8876 = () => interopDefault(import('../pages/admin/company/advertisement/create/index.vue' /* webpackChunkName: "pages/admin/company/advertisement/create/index" */))
const _545d5461 = () => interopDefault(import('../pages/admin/company/charge/error-code-modal.vue' /* webpackChunkName: "pages/admin/company/charge/error-code-modal" */))
const _f811202a = () => interopDefault(import('../pages/admin/company/matching/chat_link.vue' /* webpackChunkName: "pages/admin/company/matching/chat_link" */))
const _7d6d16fc = () => interopDefault(import('../pages/admin/company/matching/company_link.vue' /* webpackChunkName: "pages/admin/company/matching/company_link" */))
const _59f4b7d3 = () => interopDefault(import('../pages/admin/company/matching/copy_link_button.vue' /* webpackChunkName: "pages/admin/company/matching/copy_link_button" */))
const _12236b02 = () => interopDefault(import('../pages/admin/company/support/approval.vue' /* webpackChunkName: "pages/admin/company/support/approval" */))
const _96cdefde = () => interopDefault(import('../pages/admin/company/support/simultaneousness/index.vue' /* webpackChunkName: "pages/admin/company/support/simultaneousness/index" */))
const _5e02891f = () => interopDefault(import('../pages/admin/crowdfunding/campfire/create.vue' /* webpackChunkName: "pages/admin/crowdfunding/campfire/create" */))
const _46fcb164 = () => interopDefault(import('../pages/admin/ncba/bm/agreed/index.vue' /* webpackChunkName: "pages/admin/ncba/bm/agreed/index" */))
const _034690cf = () => interopDefault(import('../pages/admin/ncba/bm/create.vue' /* webpackChunkName: "pages/admin/ncba/bm/create" */))
const _5cf3a549 = () => interopDefault(import('../pages/admin/ncba/bm/formextra.vue' /* webpackChunkName: "pages/admin/ncba/bm/formextra" */))
const _732300f4 = () => interopDefault(import('../pages/admin/ncba/bm/suggesting/index.vue' /* webpackChunkName: "pages/admin/ncba/bm/suggesting/index" */))
const _78ca57c6 = () => interopDefault(import('../pages/admin/user/admin/create.vue' /* webpackChunkName: "pages/admin/user/admin/create" */))
const _3a17b358 = () => interopDefault(import('../pages/admin/user/login/history.vue' /* webpackChunkName: "pages/admin/user/login/history" */))
const _19647344 = () => interopDefault(import('../pages/admin/xba/analyse/matching/index.vue' /* webpackChunkName: "pages/admin/xba/analyse/matching/index" */))
const _6effecad = () => interopDefault(import('../pages/admin/xba/banner/create.vue' /* webpackChunkName: "pages/admin/xba/banner/create" */))
const _177212d4 = () => interopDefault(import('../pages/admin/xba/partner/information/index.vue' /* webpackChunkName: "pages/admin/xba/partner/information/index" */))
const _03b62ddc = () => interopDefault(import('../pages/admin/xba/seminar/create.vue' /* webpackChunkName: "pages/admin/xba/seminar/create" */))
const _0c13921a = () => interopDefault(import('../pages/admin/xba/service/offer/index.vue' /* webpackChunkName: "pages/admin/xba/service/offer/index" */))
const _4178488e = () => interopDefault(import('../pages/mypage/business/succession/column/index.vue' /* webpackChunkName: "pages/mypage/business/succession/column/index" */))
const _2193cc71 = () => interopDefault(import('../pages/mypage/business/succession/faq.vue' /* webpackChunkName: "pages/mypage/business/succession/faq" */))
const _5360b49e = () => interopDefault(import('../pages/mypage/crowdfunding/campfire/list.vue' /* webpackChunkName: "pages/mypage/crowdfunding/campfire/list" */))
const _6c3b4087 = () => interopDefault(import('../pages/mypage/mybusiness/needs/create.vue' /* webpackChunkName: "pages/mypage/mybusiness/needs/create" */))
const _b64386be = () => interopDefault(import('../pages/mypage/payment/card/complate.vue' /* webpackChunkName: "pages/mypage/payment/card/complate" */))
const _060a84e8 = () => interopDefault(import('../pages/mypage/payment/card/create.vue' /* webpackChunkName: "pages/mypage/payment/card/create" */))
const _09e63834 = () => interopDefault(import('../pages/mypage/setting/account/businessuser.vue' /* webpackChunkName: "pages/mypage/setting/account/businessuser" */))
const _29ef655e = () => interopDefault(import('../pages/mypage/setting/matching/search.vue' /* webpackChunkName: "pages/mypage/setting/matching/search" */))
const _f9ae5948 = () => interopDefault(import('../pages/mypage/setting/service/offer/index.vue' /* webpackChunkName: "pages/mypage/setting/service/offer/index" */))
const _46bde0d1 = () => interopDefault(import('../pages/mypage/setting/unsubscribe/compleated.vue' /* webpackChunkName: "pages/mypage/setting/unsubscribe/compleated" */))
const _5ca8a624 = () => interopDefault(import('../pages/mypage/subsidy/applysupport/form.vue' /* webpackChunkName: "pages/mypage/subsidy/applysupport/form" */))
const _738a721f = () => interopDefault(import('../pages/register/ba/components/agreement.vue' /* webpackChunkName: "pages/register/ba/components/agreement" */))
const _0cc5214a = () => interopDefault(import('../pages/register/ba/components/payment.vue' /* webpackChunkName: "pages/register/ba/components/payment" */))
const _6e361220 = () => interopDefault(import('../pages/register/ba/components/thankyou.vue' /* webpackChunkName: "pages/register/ba/components/thankyou" */))
const _1633ccbf = () => interopDefault(import('../pages/admin/bachat/interbank/contact/room.vue' /* webpackChunkName: "pages/admin/bachat/interbank/contact/room" */))
const _99716cb0 = () => interopDefault(import('../pages/admin/bachat/interbank/matching/room.vue' /* webpackChunkName: "pages/admin/bachat/interbank/matching/room" */))
const _65615dd2 = () => interopDefault(import('../pages/admin/bachat/interbank/partner/room.vue' /* webpackChunkName: "pages/admin/bachat/interbank/partner/room" */))
const _78ff04c0 = () => interopDefault(import('../pages/admin/bachat/support/bank/room.vue' /* webpackChunkName: "pages/admin/bachat/support/bank/room" */))
const _4a77d1bc = () => interopDefault(import('../pages/admin/bachat/support/bb/room.vue' /* webpackChunkName: "pages/admin/bachat/support/bb/room" */))
const _063c44cd = () => interopDefault(import('../pages/admin/company/support/simultaneousness/create.vue' /* webpackChunkName: "pages/admin/company/support/simultaneousness/create" */))
const _ccd56f58 = () => interopDefault(import('../pages/admin/xba/service/offer/import.vue' /* webpackChunkName: "pages/admin/xba/service/offer/import" */))
const _6a3de36b = () => interopDefault(import('../pages/mypage/business/succession/calculation/stock.vue' /* webpackChunkName: "pages/mypage/business/succession/calculation/stock" */))
const _b2973680 = () => interopDefault(import('../pages/mypage/business/succession/calculation/tax.vue' /* webpackChunkName: "pages/mypage/business/succession/calculation/tax" */))
const _71a39cdf = () => interopDefault(import('../pages/mypage/crowdfunding/campfire/all/list.vue' /* webpackChunkName: "pages/mypage/crowdfunding/campfire/all/list" */))
const _c7e713e4 = () => interopDefault(import('../pages/mypage/crowdfunding/campfire/bank/list.vue' /* webpackChunkName: "pages/mypage/crowdfunding/campfire/bank/list" */))
const _78bfd3d2 = () => interopDefault(import('../pages/admin/company/advertisement/create/company/search.vue' /* webpackChunkName: "pages/admin/company/advertisement/create/company/search" */))
const _a84c9366 = () => interopDefault(import('../pages/admin/company/support/simultaneousness/detail/_id.vue' /* webpackChunkName: "pages/admin/company/support/simultaneousness/detail/_id" */))
const _4ea0cf8d = () => interopDefault(import('../pages/admin/company/support/simultaneousness/target/_id.vue' /* webpackChunkName: "pages/admin/company/support/simultaneousness/target/_id" */))
const _39d0e020 = () => interopDefault(import('../pages/admin/xba/analyse/matching/detail/_id.vue' /* webpackChunkName: "pages/admin/xba/analyse/matching/detail/_id" */))
const _34a512e8 = () => interopDefault(import('../pages/admin/xba/partner/information/detail/_id.vue' /* webpackChunkName: "pages/admin/xba/partner/information/detail/_id" */))
const _393084ab = () => interopDefault(import('../pages/admin/xba/service/offer/detail/_id.vue' /* webpackChunkName: "pages/admin/xba/service/offer/detail/_id" */))
const _5dd03bf0 = () => interopDefault(import('../pages/mypage/business/succession/column/detail/_id.vue' /* webpackChunkName: "pages/mypage/business/succession/column/detail/_id" */))
const _1b4e0a1b = () => interopDefault(import('../pages/mypage/setting/service/offer/edit/_id.vue' /* webpackChunkName: "pages/mypage/setting/service/offer/edit/_id" */))
const _3f9abdc2 = () => interopDefault(import('../pages/admin/company/advertisement/detail/_id.vue' /* webpackChunkName: "pages/admin/company/advertisement/detail/_id" */))
const _1061102a = () => interopDefault(import('../pages/admin/company/charge/detail/_id.vue' /* webpackChunkName: "pages/admin/company/charge/detail/_id" */))
const _5cdd7314 = () => interopDefault(import('../pages/admin/company/creditcard/detail/_id.vue' /* webpackChunkName: "pages/admin/company/creditcard/detail/_id" */))
const _d58ea5fa = () => interopDefault(import('../pages/admin/company/favorite/detail/_id.vue' /* webpackChunkName: "pages/admin/company/favorite/detail/_id" */))
const _7b806f1a = () => interopDefault(import('../pages/admin/company/innovation/detail/_id.vue' /* webpackChunkName: "pages/admin/company/innovation/detail/_id" */))
const _6daf8e56 = () => interopDefault(import('../pages/admin/company/matching/view/_id.vue' /* webpackChunkName: "pages/admin/company/matching/view/_id" */))
const _64d2d856 = () => interopDefault(import('../pages/admin/company/needs/create/_id.vue' /* webpackChunkName: "pages/admin/company/needs/create/_id" */))
const _d62633ac = () => interopDefault(import('../pages/admin/company/needs/detail/_id.vue' /* webpackChunkName: "pages/admin/company/needs/detail/_id" */))
const _51a6ef9f = () => interopDefault(import('../pages/admin/company/partner/detail/_id.vue' /* webpackChunkName: "pages/admin/company/partner/detail/_id" */))
const _7361819f = () => interopDefault(import('../pages/admin/crowdfunding/campfire/detail/_id.vue' /* webpackChunkName: "pages/admin/crowdfunding/campfire/detail/_id" */))
const _42fa314f = () => interopDefault(import('../pages/admin/ncba/bm/detail/_id.vue' /* webpackChunkName: "pages/admin/ncba/bm/detail/_id" */))
const _0cf9afb0 = () => interopDefault(import('../pages/admin/performance/branch/detail/_id.vue' /* webpackChunkName: "pages/admin/performance/branch/detail/_id" */))
const _32c3a4c6 = () => interopDefault(import('../pages/admin/user/admin/detail/_id.vue' /* webpackChunkName: "pages/admin/user/admin/detail/_id" */))
const _38c66e2d = () => interopDefault(import('../pages/admin/xba/banner/detail/_id.vue' /* webpackChunkName: "pages/admin/xba/banner/detail/_id" */))
const _77ea5bdc = () => interopDefault(import('../pages/admin/xba/bidding/detail/_id.vue' /* webpackChunkName: "pages/admin/xba/bidding/detail/_id" */))
const _982dfe7a = () => interopDefault(import('../pages/admin/xba/column/detail/_id.vue' /* webpackChunkName: "pages/admin/xba/column/detail/_id" */))
const _190a7fdc = () => interopDefault(import('../pages/admin/xba/seminar/detail/_id.vue' /* webpackChunkName: "pages/admin/xba/seminar/detail/_id" */))
const _3d6eb3e2 = () => interopDefault(import('../pages/admin/xba/subsidy/detail/_id.vue' /* webpackChunkName: "pages/admin/xba/subsidy/detail/_id" */))
const _5335633d = () => interopDefault(import('../pages/mypage/matching/received_history/detail/_id.vue' /* webpackChunkName: "pages/mypage/matching/received_history/detail/_id" */))
const _9cee93ac = () => interopDefault(import('../pages/mypage/matching/search/detail/_ca_unique_code.vue' /* webpackChunkName: "pages/mypage/matching/search/detail/_ca_unique_code" */))
const _b5b91f98 = () => interopDefault(import('../pages/mypage/setting/account/employee/_id.vue' /* webpackChunkName: "pages/mypage/setting/account/employee/_id" */))
const _46b4c949 = () => interopDefault(import('../pages/mypage/setting/chat_remark_template/edit/_id.vue' /* webpackChunkName: "pages/mypage/setting/chat_remark_template/edit/_id" */))
const _ec71b01e = () => interopDefault(import('../pages/mypage/setting/information/edit/_id.vue' /* webpackChunkName: "pages/mypage/setting/information/edit/_id" */))
const _5e4a0cf6 = () => interopDefault(import('../pages/mypage/setting/innovation/edit/_id.vue' /* webpackChunkName: "pages/mypage/setting/innovation/edit/_id" */))
const _bb0d4c92 = () => interopDefault(import('../pages/admin/performance/monthly/detail/_year/_month.vue' /* webpackChunkName: "pages/admin/performance/monthly/detail/_year/_month" */))
const _915be67c = () => interopDefault(import('../pages/admin/activity/detail/_id.vue' /* webpackChunkName: "pages/admin/activity/detail/_id" */))
const _5abcd110 = () => interopDefault(import('../pages/admin/company/detail/_id.vue' /* webpackChunkName: "pages/admin/company/detail/_id" */))
const _2cedad16 = () => interopDefault(import('../pages/admin/companyregister/detail/_id.vue' /* webpackChunkName: "pages/admin/companyregister/detail/_id" */))
const _507fd536 = () => interopDefault(import('../pages/admin/document/detail/_id.vue' /* webpackChunkName: "pages/admin/document/detail/_id" */))
const _0bcea03b = () => interopDefault(import('../pages/admin/event/detail/_id.vue' /* webpackChunkName: "pages/admin/event/detail/_id" */))
const _7960a5c8 = () => interopDefault(import('../pages/admin/fixedphrase/detail/_id.vue' /* webpackChunkName: "pages/admin/fixedphrase/detail/_id" */))
const _d967a994 = () => interopDefault(import('../pages/admin/handout/detail/_id.vue' /* webpackChunkName: "pages/admin/handout/detail/_id" */))
const _476b502c = () => interopDefault(import('../pages/admin/innovation/detail/_id.vue' /* webpackChunkName: "pages/admin/innovation/detail/_id" */))
const _11866624 = () => interopDefault(import('../pages/admin/inquiry/detail/_id.vue' /* webpackChunkName: "pages/admin/inquiry/detail/_id" */))
const _520414a1 = () => interopDefault(import('../pages/admin/label/detail/_id.vue' /* webpackChunkName: "pages/admin/label/detail/_id" */))
const _1669a43e = () => interopDefault(import('../pages/admin/label/target/_id.vue' /* webpackChunkName: "pages/admin/label/target/_id" */))
const _fc988904 = () => interopDefault(import('../pages/admin/news/detail/_id.vue' /* webpackChunkName: "pages/admin/news/detail/_id" */))
const _aec94350 = () => interopDefault(import('../pages/admin/newsletter/detail/_id.vue' /* webpackChunkName: "pages/admin/newsletter/detail/_id" */))
const _4b627798 = () => interopDefault(import('../pages/admin/newsletter/target/_id.vue' /* webpackChunkName: "pages/admin/newsletter/target/_id" */))
const _8d71ebdc = () => interopDefault(import('../pages/admin/promotion/detail/_id.vue' /* webpackChunkName: "pages/admin/promotion/detail/_id" */))
const _53d34b86 = () => interopDefault(import('../pages/admin/user/detail/_id.vue' /* webpackChunkName: "pages/admin/user/detail/_id" */))
const _4d896ace = () => interopDefault(import('../pages/chat/invitation/organization/_code.vue' /* webpackChunkName: "pages/chat/invitation/organization/_code" */))
const _4537ebea = () => interopDefault(import('../pages/mypage/bidding/detail/_id.vue' /* webpackChunkName: "pages/mypage/bidding/detail/_id" */))
const _7e454beb = () => interopDefault(import('../pages/mypage/column/detail/_id.vue' /* webpackChunkName: "pages/mypage/column/detail/_id" */))
const _bd846bf8 = () => interopDefault(import('../pages/mypage/forum/detail/_id.vue' /* webpackChunkName: "pages/mypage/forum/detail/_id" */))
const _52e84afc = () => interopDefault(import('../pages/mypage/innovation/detail/_id.vue' /* webpackChunkName: "pages/mypage/innovation/detail/_id" */))
const _8084e626 = () => interopDefault(import('../pages/mypage/mall/detail/_id.vue' /* webpackChunkName: "pages/mypage/mall/detail/_id" */))
const _09379f20 = () => interopDefault(import('../pages/mypage/matching/company/_id.vue' /* webpackChunkName: "pages/mypage/matching/company/_id" */))
const _abf9ed82 = () => interopDefault(import('../pages/mypage/mybusiness/needs/_id.vue' /* webpackChunkName: "pages/mypage/mybusiness/needs/_id" */))
const _32ab5002 = () => interopDefault(import('../pages/mypage/news/cmc/_id.vue' /* webpackChunkName: "pages/mypage/news/cmc/_id" */))
const _41f9124e = () => interopDefault(import('../pages/mypage/news/detail/_id.vue' /* webpackChunkName: "pages/mypage/news/detail/_id" */))
const _2e8d05e8 = () => interopDefault(import('../pages/mypage/pi/detail/_id.vue' /* webpackChunkName: "pages/mypage/pi/detail/_id" */))
const _cb90cc98 = () => interopDefault(import('../pages/mypage/seminar/detail/_id.vue' /* webpackChunkName: "pages/mypage/seminar/detail/_id" */))
const _82c8648c = () => interopDefault(import('../pages/mypage/subsidy/detail/_id.vue' /* webpackChunkName: "pages/mypage/subsidy/detail/_id" */))
const _86e7dc58 = () => interopDefault(import('../pages/ncba/bm/agreement/_unique_code.vue' /* webpackChunkName: "pages/ncba/bm/agreement/_unique_code" */))
const _69fad1c3 = () => interopDefault(import('../pages/register/employee/invitation/_id.vue' /* webpackChunkName: "pages/register/employee/invitation/_id" */))
const _58d17e9c = () => interopDefault(import('../pages/mypage/bachat/_id.vue' /* webpackChunkName: "pages/mypage/bachat/_id" */))
const _8dbce450 = () => interopDefault(import('../pages/mypage/news/_id.vue' /* webpackChunkName: "pages/mypage/news/_id" */))
const _72c724be = () => interopDefault(import('../pages/news/detail/_id.vue' /* webpackChunkName: "pages/news/detail/_id" */))
const _519f12e1 = () => interopDefault(import('../pages/chat/_chatroom_unique_code.vue' /* webpackChunkName: "pages/chat/_chatroom_unique_code" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _546ffb04 = () => interopDefault(import('@/pages/mypage/news/cmc/_id.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/callback",
    component: _442485af,
    name: "callback"
  }, {
    path: "/chat",
    component: _02270576,
    name: "chat"
  }, {
    path: "/inquiry",
    component: _42156be0,
    name: "inquiry"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/logout",
    component: _49c57cf4,
    name: "logout"
  }, {
    path: "/register",
    component: _cc080460,
    name: "register"
  }, {
    path: "/reminder",
    component: _dc3c4e42,
    name: "reminder"
  }, {
    path: "/admin/activity",
    component: _74c7bc7c,
    name: "admin-activity"
  }, {
    path: "/admin/changelog",
    component: _f28cae46,
    name: "admin-changelog"
  }, {
    path: "/admin/chatlog",
    component: _ce056496,
    name: "admin-chatlog"
  }, {
    path: "/admin/chatwork",
    component: _163c6154,
    name: "admin-chatwork"
  }, {
    path: "/admin/company",
    component: _b8e44ff4,
    name: "admin-company"
  }, {
    path: "/admin/companyregister",
    component: _3c083be9,
    name: "admin-companyregister"
  }, {
    path: "/admin/dashboard",
    component: _fdd8bc46,
    name: "admin-dashboard"
  }, {
    path: "/admin/document",
    component: _04937b88,
    name: "admin-document"
  }, {
    path: "/admin/event",
    component: _3db92163,
    name: "admin-event"
  }, {
    path: "/admin/file",
    component: _fbf698ae,
    name: "admin-file"
  }, {
    path: "/admin/fixedphrase",
    component: _259317b6,
    name: "admin-fixedphrase"
  }, {
    path: "/admin/handout",
    component: _062d34f0,
    name: "admin-handout"
  }, {
    path: "/admin/innovation",
    component: _f4cf025c,
    name: "admin-innovation"
  }, {
    path: "/admin/inquiry",
    component: _099890d0,
    name: "admin-inquiry"
  }, {
    path: "/admin/label",
    component: _739a053d,
    name: "admin-label"
  }, {
    path: "/admin/mall",
    component: _4d3de641,
    name: "admin-mall"
  }, {
    path: "/admin/news",
    component: _500ac840,
    name: "admin-news"
  }, {
    path: "/admin/newsletter",
    component: _4ae975b4,
    name: "admin-newsletter"
  }, {
    path: "/admin/partnerform",
    component: _25351355,
    name: "admin-partnerform"
  }, {
    path: "/admin/pickup",
    component: _607b4c29,
    name: "admin-pickup"
  }, {
    path: "/admin/promotion",
    component: _7f5dad2c,
    name: "admin-promotion"
  }, {
    path: "/admin/user",
    component: _68e35338,
    name: "admin-user"
  }, {
    path: "/chat/remark-body",
    component: _7816f4fc,
    name: "chat-remark-body"
  }, {
    path: "/disconnect/broker",
    component: _072d5432,
    name: "disconnect-broker"
  }, {
    path: "/inquiry/completed",
    component: _750d7009,
    name: "inquiry-completed"
  }, {
    path: "/mypage/babank",
    component: _5add6518,
    name: "mypage-babank"
  }, {
    path: "/mypage/bachat",
    component: _cb558198,
    name: "mypage-bachat"
  }, {
    path: "/mypage/bidding",
    component: _32a5f758,
    name: "mypage-bidding"
  }, {
    path: "/mypage/chat",
    component: _298055b5,
    name: "mypage-chat"
  }, {
    path: "/mypage/column",
    component: _5251e7b3,
    name: "mypage-column"
  }, {
    path: "/mypage/dashboard",
    component: _4158f8ad,
    name: "mypage-dashboard"
  }, {
    path: "/mypage/forum",
    component: _a331840c,
    name: "mypage-forum"
  }, {
    path: "/mypage/help",
    component: _6f48fb44,
    name: "mypage-help"
  }, {
    path: "/mypage/innovation",
    component: _6403fbfc,
    name: "mypage-innovation"
  }, {
    path: "/mypage/mall",
    component: _24118d71,
    name: "mypage-mall"
  }, {
    path: "/mypage/matching",
    component: _5813331a,
    name: "mypage-matching"
  }, {
    path: "/mypage/mybusiness",
    component: _1c212149,
    name: "mypage-mybusiness"
  }, {
    path: "/mypage/news",
    component: _26de6f70,
    name: "mypage-news"
  }, {
    path: "/mypage/sc",
    component: _6ca839a6,
    name: "mypage-sc"
  }, {
    path: "/mypage/seminar",
    component: _0fd33f6c,
    name: "mypage-seminar"
  }, {
    path: "/mypage/subsidy",
    component: _ef917ef8,
    name: "mypage-subsidy"
  }, {
    path: "/mypage/succession",
    component: _96c43c7c,
    name: "mypage-succession"
  }, {
    path: "/mypage/support",
    component: _322130b0,
    name: "mypage-support"
  }, {
    path: "/mypage/trend",
    component: _a1197114,
    name: "mypage-trend"
  }, {
    path: "/page/bm_rule",
    component: _6b48f9da,
    name: "page-bm_rule"
  }, {
    path: "/page/cookie_policy",
    component: _3d7e72d7,
    name: "page-cookie_policy"
  }, {
    path: "/page/rule",
    component: _4091affc,
    name: "page-rule"
  }, {
    path: "/page/rule_portal",
    component: _ec1c0c0e,
    name: "page-rule_portal"
  }, {
    path: "/page/rule_rc",
    component: _1b41d7be,
    name: "page-rule_rc"
  }, {
    path: "/page/suspending",
    component: _63d29fac,
    name: "page-suspending"
  }, {
    path: "/register/agreement",
    component: _ce8aaff0,
    name: "register-agreement"
  }, {
    path: "/register/ba",
    component: _48bf9374,
    name: "register-ba"
  }, {
    path: "/register/bizsol",
    component: _4f499f5c,
    name: "register-bizsol"
  }, {
    path: "/register/ibmft",
    component: _097b89c3,
    name: "register-ibmft"
  }, {
    path: "/register/payment",
    component: _c3f7e8f8,
    name: "register-payment"
  }, {
    path: "/register/portal",
    component: _8551033e,
    name: "register-portal"
  }, {
    path: "/register/social",
    component: _0c67dcdf,
    name: "register-social"
  }, {
    path: "/register/thankyou",
    component: _9d5c4032,
    name: "register-thankyou"
  }, {
    path: "/reminder/completed",
    component: _a3300150,
    name: "reminder-completed"
  }, {
    path: "/admin/bachat/noreply",
    component: _a1988584,
    name: "admin-bachat-noreply"
  }, {
    path: "/admin/bachat/stats",
    component: _433cdd5e,
    name: "admin-bachat-stats"
  }, {
    path: "/admin/chat/remarks",
    component: _7e08c134,
    name: "admin-chat-remarks"
  }, {
    path: "/admin/company/advertisement",
    component: _3c146b7c,
    name: "admin-company-advertisement"
  }, {
    path: "/admin/company/bulk-create",
    component: _ce9937e4,
    name: "admin-company-bulk-create"
  }, {
    path: "/admin/company/charge",
    component: _03c7889a,
    name: "admin-company-charge"
  }, {
    path: "/admin/company/create",
    component: _143d1910,
    name: "admin-company-create"
  }, {
    path: "/admin/company/creditcard",
    component: _0c304248,
    name: "admin-company-creditcard"
  }, {
    path: "/admin/company/favorite",
    component: _4c5c379b,
    name: "admin-company-favorite"
  }, {
    path: "/admin/company/innovation",
    component: _cae979b8,
    name: "admin-company-innovation"
  }, {
    path: "/admin/company/matching",
    component: _483b5688,
    name: "admin-company-matching"
  }, {
    path: "/admin/company/needs",
    component: _67d4a214,
    name: "admin-company-needs"
  }, {
    path: "/admin/company/partner",
    component: _6b3dc37f,
    name: "admin-company-partner"
  }, {
    path: "/admin/company/user",
    component: _69e785ec,
    name: "admin-company-user"
  }, {
    path: "/admin/crowdfunding/campfire",
    component: _80a01d02,
    name: "admin-crowdfunding-campfire"
  }, {
    path: "/admin/document/create",
    component: _94df7f94,
    name: "admin-document-create"
  }, {
    path: "/admin/file/request",
    component: _47140326,
    name: "admin-file-request"
  }, {
    path: "/admin/fixedphrase/create",
    component: _96f7ac70,
    name: "admin-fixedphrase-create"
  }, {
    path: "/admin/fixedphrase/form",
    component: _0e6aee90,
    name: "admin-fixedphrase-form"
  }, {
    path: "/admin/handout/create",
    component: _7010d394,
    name: "admin-handout-create"
  }, {
    path: "/admin/label/create",
    component: _275aed21,
    name: "admin-label-create"
  }, {
    path: "/admin/ncba/bm",
    component: _fa179862,
    name: "admin-ncba-bm"
  }, {
    path: "/admin/news/create",
    component: _4dfaeb04,
    name: "admin-news-create"
  }, {
    path: "/admin/newsletter/create",
    component: _c2dcab50,
    name: "admin-newsletter-create"
  }, {
    path: "/admin/performance/branch",
    component: _e0b50664,
    name: "admin-performance-branch"
  }, {
    path: "/admin/performance/monthly",
    component: _63db4a77,
    name: "admin-performance-monthly"
  }, {
    path: "/admin/promotion/create",
    component: _d7e779dc,
    name: "admin-promotion-create"
  }, {
    path: "/admin/setting/global",
    component: _66daac6f,
    name: "admin-setting-global"
  }, {
    path: "/admin/setting/invoice",
    component: _b707f998,
    name: "admin-setting-invoice"
  }, {
    path: "/admin/setting/permission",
    component: _7953871b,
    name: "admin-setting-permission"
  }, {
    path: "/admin/user/admin",
    component: _4cf031f8,
    name: "admin-user-admin"
  }, {
    path: "/admin/user/password",
    component: _fa399a30,
    name: "admin-user-password"
  }, {
    path: "/admin/xba/banner",
    component: _12d0e431,
    name: "admin-xba-banner"
  }, {
    path: "/admin/xba/bidding",
    component: _a94635a8,
    name: "admin-xba-bidding"
  }, {
    path: "/admin/xba/column",
    component: _eb92e84a,
    name: "admin-xba-column"
  }, {
    path: "/admin/xba/seminar",
    component: _86737dbc,
    name: "admin-xba-seminar"
  }, {
    path: "/admin/xba/subsidy",
    component: _4ce7215c,
    name: "admin-xba-subsidy"
  }, {
    path: "/mypage/chanto-attendance/registration",
    component: _225814f9,
    name: "mypage-chanto-attendance-registration"
  }, {
    path: "/mypage/chanto-attendance/top",
    component: _93559376,
    name: "mypage-chanto-attendance-top"
  }, {
    path: "/mypage/company/edit",
    component: _d6926494,
    name: "mypage-company-edit"
  }, {
    path: "/mypage/crowdfunding/campfire",
    component: _0f19904f,
    name: "mypage-crowdfunding-campfire"
  }, {
    path: "/mypage/dashboard/employee",
    component: _18e57123,
    name: "mypage-dashboard-employee"
  }, {
    path: "/mypage/dashboard/owner",
    component: _7287ea0e,
    name: "mypage-dashboard-owner"
  }, {
    path: "/mypage/forum/CategoryFilter",
    component: _14f0cf84,
    name: "mypage-forum-CategoryFilter"
  }, {
    path: "/mypage/forum/form",
    component: _1b16efcc,
    name: "mypage-forum-form"
  }, {
    path: "/mypage/forum/list",
    component: _4f1016a6,
    name: "mypage-forum-list"
  }, {
    path: "/mypage/forum/policy",
    component: _5393c53a,
    name: "mypage-forum-policy"
  }, {
    path: "/mypage/forum/TopicCard",
    component: _62128932,
    name: "mypage-forum-TopicCard"
  }, {
    path: "/mypage/mall/policy",
    component: _d99936ba,
    name: "mypage-mall-policy"
  }, {
    path: "/mypage/matching/campaign",
    component: _5bc89cf8,
    name: "mypage-matching-campaign"
  }, {
    path: "/mypage/matching/favca",
    component: _0858fb81,
    name: "mypage-matching-favca"
  }, {
    path: "/mypage/matching/history",
    component: _b8225c48,
    name: "mypage-matching-history"
  }, {
    path: "/mypage/matching/received_history",
    component: _69fdf5be,
    name: "mypage-matching-received_history"
  }, {
    path: "/mypage/matching/search",
    component: _2415b7da,
    name: "mypage-matching-search"
  }, {
    path: "/mypage/mybusiness/edit",
    component: _6585963a,
    name: "mypage-mybusiness-edit"
  }, {
    path: "/mypage/sc/admin",
    component: _09c991ac,
    name: "mypage-sc-admin"
  }, {
    path: "/mypage/setting/account",
    component: _428bd487,
    name: "mypage-setting-account"
  }, {
    path: "/mypage/setting/chat_remark_template",
    component: _276fda24,
    name: "mypage-setting-chat_remark_template"
  }, {
    path: "/mypage/setting/information",
    component: _baab1574,
    name: "mypage-setting-information"
  }, {
    path: "/mypage/setting/innovation",
    component: _505f933e,
    name: "mypage-setting-innovation"
  }, {
    path: "/mypage/setting/invoice",
    component: _2f8964f2,
    name: "mypage-setting-invoice"
  }, {
    path: "/mypage/setting/password",
    component: _dfff8658,
    name: "mypage-setting-password"
  }, {
    path: "/mypage/setting/profile",
    component: _ba8bfb00,
    name: "mypage-setting-profile"
  }, {
    path: "/mypage/setting/unsubscribe",
    component: _54bdbeaa,
    name: "mypage-setting-unsubscribe"
  }, {
    path: "/mypage/setting/website",
    component: _68837975,
    name: "mypage-setting-website"
  }, {
    path: "/mypage/subsidy/applysupport",
    component: _c1e75abc,
    name: "mypage-subsidy-applysupport"
  }, {
    path: "/mypage/subsidy/search",
    component: _2a6fa966,
    name: "mypage-subsidy-search"
  }, {
    path: "/mypage/succession/compleated",
    component: _385d93cc,
    name: "mypage-succession-compleated"
  }, {
    path: "/mypage/support/document",
    component: _75916f96,
    name: "mypage-support-document"
  }, {
    path: "/register/ba/components",
    component: _5b7a6b67,
    name: "register-ba-components"
  }, {
    path: "/register/bizsol/as_employee",
    component: _2604d11b,
    name: "register-bizsol-as_employee"
  }, {
    path: "/register/bizsol/as_owner",
    component: _004d4d16,
    name: "register-bizsol-as_owner"
  }, {
    path: "/register/bizsol/progress",
    component: _948e4146,
    name: "register-bizsol-progress"
  }, {
    path: "/register/employee/thankyou",
    component: _16f17598,
    name: "register-employee-thankyou"
  }, {
    path: "/register/ibmft/as_employee",
    component: _635e91cc,
    name: "register-ibmft-as_employee"
  }, {
    path: "/register/ibmft/as_owner",
    component: _38549bf6,
    name: "register-ibmft-as_owner"
  }, {
    path: "/register/ibmft/progress",
    component: _cd7d7768,
    name: "register-ibmft-progress"
  }, {
    path: "/register/portal/enable",
    component: _34888184,
    name: "register-portal-enable"
  }, {
    path: "/register/portal/enabled",
    component: _5f5fef90,
    name: "register-portal-enabled"
  }, {
    path: "/register/portal/index_as_employee",
    component: _5bb88d3d,
    name: "register-portal-index_as_employee"
  }, {
    path: "/register/portal/index_as_owner",
    component: _2d05d434,
    name: "register-portal-index_as_owner"
  }, {
    path: "/register/portal/thankyou",
    component: _468849b6,
    name: "register-portal-thankyou"
  }, {
    path: "/admin/bachat/interbank/contact",
    component: _7b3905c4,
    name: "admin-bachat-interbank-contact"
  }, {
    path: "/admin/bachat/interbank/partner",
    component: _228ef874,
    name: "admin-bachat-interbank-partner"
  }, {
    path: "/admin/bachat/support/bank",
    component: _8e017586,
    name: "admin-bachat-support-bank"
  }, {
    path: "/admin/bachat/support/bb",
    component: _16a017c1,
    name: "admin-bachat-support-bb"
  }, {
    path: "/admin/boy/intrauser/import",
    component: _18477350,
    name: "admin-boy-intrauser-import"
  }, {
    path: "/admin/company/advertisement/create",
    component: _ae7f8876,
    name: "admin-company-advertisement-create"
  }, {
    path: "/admin/company/charge/error-code-modal",
    component: _545d5461,
    name: "admin-company-charge-error-code-modal"
  }, {
    path: "/admin/company/matching/chat_link",
    component: _f811202a,
    name: "admin-company-matching-chat_link"
  }, {
    path: "/admin/company/matching/company_link",
    component: _7d6d16fc,
    name: "admin-company-matching-company_link"
  }, {
    path: "/admin/company/matching/copy_link_button",
    component: _59f4b7d3,
    name: "admin-company-matching-copy_link_button"
  }, {
    path: "/admin/company/support/approval",
    component: _12236b02,
    name: "admin-company-support-approval"
  }, {
    path: "/admin/company/support/simultaneousness",
    component: _96cdefde,
    name: "admin-company-support-simultaneousness"
  }, {
    path: "/admin/crowdfunding/campfire/create",
    component: _5e02891f,
    name: "admin-crowdfunding-campfire-create"
  }, {
    path: "/admin/ncba/bm/agreed",
    component: _46fcb164,
    name: "admin-ncba-bm-agreed"
  }, {
    path: "/admin/ncba/bm/create",
    component: _034690cf,
    name: "admin-ncba-bm-create"
  }, {
    path: "/admin/ncba/bm/formextra",
    component: _5cf3a549,
    name: "admin-ncba-bm-formextra"
  }, {
    path: "/admin/ncba/bm/suggesting",
    component: _732300f4,
    name: "admin-ncba-bm-suggesting"
  }, {
    path: "/admin/user/admin/create",
    component: _78ca57c6,
    name: "admin-user-admin-create"
  }, {
    path: "/admin/user/login/history",
    component: _3a17b358,
    name: "admin-user-login-history"
  }, {
    path: "/admin/xba/analyse/matching",
    component: _19647344,
    name: "admin-xba-analyse-matching"
  }, {
    path: "/admin/xba/banner/create",
    component: _6effecad,
    name: "admin-xba-banner-create"
  }, {
    path: "/admin/xba/partner/information",
    component: _177212d4,
    name: "admin-xba-partner-information"
  }, {
    path: "/admin/xba/seminar/create",
    component: _03b62ddc,
    name: "admin-xba-seminar-create"
  }, {
    path: "/admin/xba/service/offer",
    component: _0c13921a,
    name: "admin-xba-service-offer"
  }, {
    path: "/mypage/business/succession/column",
    component: _4178488e,
    name: "mypage-business-succession-column"
  }, {
    path: "/mypage/business/succession/faq",
    component: _2193cc71,
    name: "mypage-business-succession-faq"
  }, {
    path: "/mypage/crowdfunding/campfire/list",
    component: _5360b49e,
    name: "mypage-crowdfunding-campfire-list"
  }, {
    path: "/mypage/mybusiness/needs/create",
    component: _6c3b4087,
    name: "mypage-mybusiness-needs-create"
  }, {
    path: "/mypage/payment/card/complate",
    component: _b64386be,
    name: "mypage-payment-card-complate"
  }, {
    path: "/mypage/payment/card/create",
    component: _060a84e8,
    name: "mypage-payment-card-create"
  }, {
    path: "/mypage/setting/account/businessuser",
    component: _09e63834,
    name: "mypage-setting-account-businessuser"
  }, {
    path: "/mypage/setting/matching/search",
    component: _29ef655e,
    name: "mypage-setting-matching-search"
  }, {
    path: "/mypage/setting/service/offer",
    component: _f9ae5948,
    name: "mypage-setting-service-offer"
  }, {
    path: "/mypage/setting/unsubscribe/compleated",
    component: _46bde0d1,
    name: "mypage-setting-unsubscribe-compleated"
  }, {
    path: "/mypage/subsidy/applysupport/form",
    component: _5ca8a624,
    name: "mypage-subsidy-applysupport-form"
  }, {
    path: "/register/ba/components/agreement",
    component: _738a721f,
    name: "register-ba-components-agreement"
  }, {
    path: "/register/ba/components/payment",
    component: _0cc5214a,
    name: "register-ba-components-payment"
  }, {
    path: "/register/ba/components/thankyou",
    component: _6e361220,
    name: "register-ba-components-thankyou"
  }, {
    path: "/admin/bachat/interbank/contact/room",
    component: _1633ccbf,
    name: "admin-bachat-interbank-contact-room"
  }, {
    path: "/admin/bachat/interbank/matching/room",
    component: _99716cb0,
    name: "admin-bachat-interbank-matching-room"
  }, {
    path: "/admin/bachat/interbank/partner/room",
    component: _65615dd2,
    name: "admin-bachat-interbank-partner-room"
  }, {
    path: "/admin/bachat/support/bank/room",
    component: _78ff04c0,
    name: "admin-bachat-support-bank-room"
  }, {
    path: "/admin/bachat/support/bb/room",
    component: _4a77d1bc,
    name: "admin-bachat-support-bb-room"
  }, {
    path: "/admin/company/support/simultaneousness/create",
    component: _063c44cd,
    name: "admin-company-support-simultaneousness-create"
  }, {
    path: "/admin/xba/service/offer/import",
    component: _ccd56f58,
    name: "admin-xba-service-offer-import"
  }, {
    path: "/mypage/business/succession/calculation/stock",
    component: _6a3de36b,
    name: "mypage-business-succession-calculation-stock"
  }, {
    path: "/mypage/business/succession/calculation/tax",
    component: _b2973680,
    name: "mypage-business-succession-calculation-tax"
  }, {
    path: "/mypage/crowdfunding/campfire/all/list",
    component: _71a39cdf,
    name: "mypage-crowdfunding-campfire-all-list"
  }, {
    path: "/mypage/crowdfunding/campfire/bank/list",
    component: _c7e713e4,
    name: "mypage-crowdfunding-campfire-bank-list"
  }, {
    path: "/admin/company/advertisement/create/company/search",
    component: _78bfd3d2,
    name: "admin-company-advertisement-create-company-search"
  }, {
    path: "/admin/company/support/simultaneousness/detail/:id?",
    component: _a84c9366,
    name: "admin-company-support-simultaneousness-detail-id"
  }, {
    path: "/admin/company/support/simultaneousness/target/:id?",
    component: _4ea0cf8d,
    name: "admin-company-support-simultaneousness-target-id"
  }, {
    path: "/admin/xba/analyse/matching/detail/:id?",
    component: _39d0e020,
    name: "admin-xba-analyse-matching-detail-id"
  }, {
    path: "/admin/xba/partner/information/detail/:id?",
    component: _34a512e8,
    name: "admin-xba-partner-information-detail-id"
  }, {
    path: "/admin/xba/service/offer/detail/:id?",
    component: _393084ab,
    name: "admin-xba-service-offer-detail-id"
  }, {
    path: "/mypage/business/succession/column/detail/:id?",
    component: _5dd03bf0,
    name: "mypage-business-succession-column-detail-id"
  }, {
    path: "/mypage/setting/service/offer/edit/:id?",
    component: _1b4e0a1b,
    name: "mypage-setting-service-offer-edit-id"
  }, {
    path: "/admin/company/advertisement/detail/:id?",
    component: _3f9abdc2,
    name: "admin-company-advertisement-detail-id"
  }, {
    path: "/admin/company/charge/detail/:id?",
    component: _1061102a,
    name: "admin-company-charge-detail-id"
  }, {
    path: "/admin/company/creditcard/detail/:id?",
    component: _5cdd7314,
    name: "admin-company-creditcard-detail-id"
  }, {
    path: "/admin/company/favorite/detail/:id?",
    component: _d58ea5fa,
    name: "admin-company-favorite-detail-id"
  }, {
    path: "/admin/company/innovation/detail/:id?",
    component: _7b806f1a,
    name: "admin-company-innovation-detail-id"
  }, {
    path: "/admin/company/matching/view/:id?",
    component: _6daf8e56,
    name: "admin-company-matching-view-id"
  }, {
    path: "/admin/company/needs/create/:id?",
    component: _64d2d856,
    name: "admin-company-needs-create-id"
  }, {
    path: "/admin/company/needs/detail/:id?",
    component: _d62633ac,
    name: "admin-company-needs-detail-id"
  }, {
    path: "/admin/company/partner/detail/:id?",
    component: _51a6ef9f,
    name: "admin-company-partner-detail-id"
  }, {
    path: "/admin/crowdfunding/campfire/detail/:id?",
    component: _7361819f,
    name: "admin-crowdfunding-campfire-detail-id"
  }, {
    path: "/admin/ncba/bm/detail/:id?",
    component: _42fa314f,
    name: "admin-ncba-bm-detail-id"
  }, {
    path: "/admin/performance/branch/detail/:id?",
    component: _0cf9afb0,
    name: "admin-performance-branch-detail-id"
  }, {
    path: "/admin/user/admin/detail/:id?",
    component: _32c3a4c6,
    name: "admin-user-admin-detail-id"
  }, {
    path: "/admin/xba/banner/detail/:id?",
    component: _38c66e2d,
    name: "admin-xba-banner-detail-id"
  }, {
    path: "/admin/xba/bidding/detail/:id?",
    component: _77ea5bdc,
    name: "admin-xba-bidding-detail-id"
  }, {
    path: "/admin/xba/column/detail/:id?",
    component: _982dfe7a,
    name: "admin-xba-column-detail-id"
  }, {
    path: "/admin/xba/seminar/detail/:id?",
    component: _190a7fdc,
    name: "admin-xba-seminar-detail-id"
  }, {
    path: "/admin/xba/subsidy/detail/:id?",
    component: _3d6eb3e2,
    name: "admin-xba-subsidy-detail-id"
  }, {
    path: "/mypage/matching/received_history/detail/:id?",
    component: _5335633d,
    name: "mypage-matching-received_history-detail-id"
  }, {
    path: "/mypage/matching/search/detail/:ca_unique_code?",
    component: _9cee93ac,
    name: "mypage-matching-search-detail-ca_unique_code"
  }, {
    path: "/mypage/setting/account/employee/:id?",
    component: _b5b91f98,
    name: "mypage-setting-account-employee-id"
  }, {
    path: "/mypage/setting/chat_remark_template/edit/:id?",
    component: _46b4c949,
    name: "mypage-setting-chat_remark_template-edit-id"
  }, {
    path: "/mypage/setting/information/edit/:id?",
    component: _ec71b01e,
    name: "mypage-setting-information-edit-id"
  }, {
    path: "/mypage/setting/innovation/edit/:id?",
    component: _5e4a0cf6,
    name: "mypage-setting-innovation-edit-id"
  }, {
    path: "/admin/performance/monthly/detail/:year?/:month?",
    component: _bb0d4c92,
    name: "admin-performance-monthly-detail-year-month"
  }, {
    path: "/admin/activity/detail/:id?",
    component: _915be67c,
    name: "admin-activity-detail-id"
  }, {
    path: "/admin/company/detail/:id?",
    component: _5abcd110,
    name: "admin-company-detail-id"
  }, {
    path: "/admin/companyregister/detail/:id?",
    component: _2cedad16,
    name: "admin-companyregister-detail-id"
  }, {
    path: "/admin/document/detail/:id?",
    component: _507fd536,
    name: "admin-document-detail-id"
  }, {
    path: "/admin/event/detail/:id?",
    component: _0bcea03b,
    name: "admin-event-detail-id"
  }, {
    path: "/admin/fixedphrase/detail/:id?",
    component: _7960a5c8,
    name: "admin-fixedphrase-detail-id"
  }, {
    path: "/admin/handout/detail/:id?",
    component: _d967a994,
    name: "admin-handout-detail-id"
  }, {
    path: "/admin/innovation/detail/:id?",
    component: _476b502c,
    name: "admin-innovation-detail-id"
  }, {
    path: "/admin/inquiry/detail/:id?",
    component: _11866624,
    name: "admin-inquiry-detail-id"
  }, {
    path: "/admin/label/detail/:id?",
    component: _520414a1,
    name: "admin-label-detail-id"
  }, {
    path: "/admin/label/target/:id?",
    component: _1669a43e,
    name: "admin-label-target-id"
  }, {
    path: "/admin/news/detail/:id?",
    component: _fc988904,
    name: "admin-news-detail-id"
  }, {
    path: "/admin/newsletter/detail/:id?",
    component: _aec94350,
    name: "admin-newsletter-detail-id"
  }, {
    path: "/admin/newsletter/target/:id?",
    component: _4b627798,
    name: "admin-newsletter-target-id"
  }, {
    path: "/admin/promotion/detail/:id?",
    component: _8d71ebdc,
    name: "admin-promotion-detail-id"
  }, {
    path: "/admin/user/detail/:id?",
    component: _53d34b86,
    name: "admin-user-detail-id"
  }, {
    path: "/chat/invitation/organization/:code?",
    component: _4d896ace,
    name: "chat-invitation-organization-code"
  }, {
    path: "/mypage/bidding/detail/:id?",
    component: _4537ebea,
    name: "mypage-bidding-detail-id"
  }, {
    path: "/mypage/column/detail/:id?",
    component: _7e454beb,
    name: "mypage-column-detail-id"
  }, {
    path: "/mypage/forum/detail/:id?",
    component: _bd846bf8,
    name: "mypage-forum-detail-id"
  }, {
    path: "/mypage/innovation/detail/:id?",
    component: _52e84afc,
    name: "mypage-innovation-detail-id"
  }, {
    path: "/mypage/mall/detail/:id?",
    component: _8084e626,
    name: "mypage-mall-detail-id"
  }, {
    path: "/mypage/matching/company/:id?",
    component: _09379f20,
    name: "mypage-matching-company-id"
  }, {
    path: "/mypage/mybusiness/needs/:id?",
    component: _abf9ed82,
    name: "mypage-mybusiness-needs-id"
  }, {
    path: "/mypage/news/cmc/:id?",
    component: _32ab5002,
    name: "mypage-news-cmc-id"
  }, {
    path: "/mypage/news/detail/:id?",
    component: _41f9124e,
    name: "mypage-news-detail-id"
  }, {
    path: "/mypage/pi/detail/:id?",
    component: _2e8d05e8,
    name: "mypage-pi-detail-id"
  }, {
    path: "/mypage/seminar/detail/:id?",
    component: _cb90cc98,
    name: "mypage-seminar-detail-id"
  }, {
    path: "/mypage/subsidy/detail/:id?",
    component: _82c8648c,
    name: "mypage-subsidy-detail-id"
  }, {
    path: "/ncba/bm/agreement/:unique_code?",
    component: _86e7dc58,
    name: "ncba-bm-agreement-unique_code"
  }, {
    path: "/register/employee/invitation/:id?",
    component: _69fad1c3,
    name: "register-employee-invitation-id"
  }, {
    path: "/mypage/bachat/:id",
    component: _58d17e9c,
    name: "mypage-bachat-id"
  }, {
    path: "/mypage/news/:id",
    component: _8dbce450,
    name: "mypage-news-id"
  }, {
    path: "/news/detail/:id?",
    component: _72c724be,
    name: "news-detail-id"
  }, {
    path: "/chat/:chatroom_unique_code",
    component: _519f12e1,
    name: "chat-chatroom_unique_code"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/mypage/cmc/:id",
    component: _546ffb04,
    name: "mypage-cmc-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
